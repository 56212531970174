import { html, render } from 'lit-html';
import * as bannerStyles from 'url:./style.css';
import * as outputStyles from 'url:../../output.css';
import * as brandStyles from 'url:../../brand.css';

class UpdatesBanner extends HTMLElement {
  constructor() {
    super();
    this.shadow = this.attachShadow({ mode: "open" });
  }

  static get observedAttributes() {
    return [];
  }

  attributeChangedCallback() {
    this.render();
  }

  connectedCallback() {
    this.getBannerContent();
  }

  closeBanner() {
    const overlay = this.shadow.querySelector('.banner-overlay');
    if (overlay) {
      overlay.remove();
    }
  }

  async getBannerContent() {
    try {
      const response = await fetch(`/announcements`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (data) {
        const active = data.active;
        const message = data.message;
        const linkText = data.linkText;
        const linkHref = data.linkHref;

        if (active) {
          this.render();
          const bannerContent = `${message} <a href="${linkHref}" target="_blank" class="whitespace-nowrap font-semibold">${linkText}&nbsp;<span aria-hidden="true">&rarr;</span></a>`;
          this.shadow.querySelector("#bannerMessage").innerHTML = bannerContent;
          const bannerClose = this.shadow.querySelector('#bannerClose');
          bannerClose.addEventListener('click', () => this.closeBanner());
        }
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  render() {
    const template = html`
      <link
        href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;900&display=swap"
        rel="stylesheet"
      />
      <link href="${outputStyles}" rel="stylesheet">
      <link href="${bannerStyles}" rel="stylesheet">
      <link href="${brandStyles}" rel="stylesheet">
      <div class="relative isolate flex items-center gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5 sm:before:flex-1 banner-overlay">
        <p id="bannerMessage" class="text-sm leading-6 text-white future-font">
        </p>
        <div class="flex flex-1 justify-end">
          <button id="bannerClose" type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]">
            <span class="sr-only">Dismiss</span>
            <svg class="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
      </svg>
          </button>
        </div>
      </div>
    `;

    render(template, this.shadow);
  }
}

customElements.define("updates-banner", UpdatesBanner);
